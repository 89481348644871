(function(global) {
  'use strict';

  if (typeof global.habTracker === 'undefined') {
    global.habTracker = {};
  }

  global.habTracker.track = function (eventName, eventProperties, callback) {
    window.habTracker.amplitude.track(eventName, eventProperties, callback);
  };

  window.VWO = window.VWO || [];
  VWO.event = VWO.event || function () {
    VWO.push(["event"].concat([].slice.call(arguments)));
  };

})(window);
