const sideMenuHeader = document.querySelectorAll(".wc-side-menu__header");
const sideMenuFooter = document.querySelectorAll(".wc-side-menu__footer");
const submenuItems = document.querySelectorAll(".submenu-item");
const dataModuleItems = document.querySelectorAll("[data-module]");
const submenuCategories = document.querySelectorAll(".submenu-category");
const submenuReturns = document.querySelectorAll(".submenu-return");

function hideSidebarElements() {
  sideMenuHeader.forEach(element => element.style.display = "none");
  sideMenuFooter.forEach(element => element.style.display = "none");
  dataModuleItems.forEach(element => element.style.display = "none");
}

function showSidebarElements() {
  sideMenuHeader.forEach(element => element.style.display = "block");
  sideMenuFooter.forEach(element => element.style.display = "block");
  dataModuleItems.forEach(element => element.style.display = "block");
}

function handleSubMenuVisibility(submenuContent, itemLink) {
  submenuItems.forEach(item => item.querySelector("span").classList.add("inactive"));
  submenuContent.classList.remove("inactive");
  submenuContent.classList.add("active");
  itemLink.classList.add("inactive");
}

submenuItems.forEach(item => {
  const itemLink = item.querySelector("span");
  const submenuContent = item.querySelector("div.submenu-content");

  itemLink.addEventListener("click", () => {
    handleSubMenuVisibility(submenuContent, itemLink);
    hideSidebarElements();
  });
});

submenuCategories.forEach(category => {
  category.addEventListener("click", () => {
    const submenuFirstLevel = category.parentNode.parentNode;
    const submenuFirstLevelHeading = submenuFirstLevel.querySelectorAll(".first-level-heading");
    const submenuFirstLevelContent = submenuFirstLevel.querySelectorAll(".first-level-content");

    submenuFirstLevelHeading.forEach(item => item.classList.add("inactive"));
    submenuFirstLevelContent.forEach(item => item.classList.add("inactive"));

    const submenuSecondLevel = category.querySelector(".submenu-second-level");
    submenuSecondLevel.classList.remove("inactive");
    submenuSecondLevel.classList.add("active");
  });
});

submenuReturns.forEach(returnBtn => {
  returnBtn.addEventListener("click", () => {
    const submenuContainer = returnBtn.closest(".submenu-content");
    const firstLevelMenu = submenuContainer.querySelector(".submenu-first-level");
    const secondLevelMenu = firstLevelMenu.querySelector(".submenu-second-level.active");

    if (secondLevelMenu) {
      const submenuFirstLevelHeading = firstLevelMenu.querySelectorAll(".first-level-heading");
      const submenuFirstLevelContent = submenuContainer.querySelectorAll(".first-level-content");

      submenuFirstLevelHeading.forEach(item => item.classList.remove("inactive"));
      submenuFirstLevelContent.forEach(item => item.classList.remove("inactive"));

      secondLevelMenu.classList.remove("active");
      secondLevelMenu.classList.add("inactive");
    } else {
      submenuContainer.classList.remove("active");
      submenuContainer.classList.add("inactive");
      submenuItems.forEach(item => item.querySelector("span").classList.remove("inactive"));
      showSidebarElements();
    }
  });
});
