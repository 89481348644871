(function (global) {
  'use strict';

  if (typeof global.habTracker === 'undefined') {
    global.habTracker = {};
  }

  global.habTracker.amplitude = {
    eventsQueue: [],
    trackedUserProperties: false,

    isActive: function () {
      return window.habTrackerOptions.amplitude.isActive;
    },

    getProject: function () {
      return window.habTrackerOptions.amplitude.project;
    },

    enqueueEvents: function (eventName, eventProperties, callback) {
      this.eventsQueue.push([eventName, eventProperties, callback]);
    },

    trackQueueEvents: function () {
      if (!window.amplitude) return;

      var eventsToSend = this.eventsQueue;
      this.eventsQueue = [];

      for (var index = 0; index < eventsToSend.length; index++) {
        var element = eventsToSend[index];
        this.track.apply(this, element);
      }
    },

    // @deprecated Use habTracker.track
    trackEvent: function (eventName, eventProperties, callback) {
      console.warn('Caution: this method is deprecated. You should use `habTracker.track` instead');
      this.track(eventName, eventProperties, callback)
    },

    track: function (eventName, eventProperties, callback) {
      eventProperties = typeof eventProperties !== 'undefined' ? eventProperties : {};

      if ('experiment' in eventProperties) {
        console.warn(
          'WARNING: properties like these are discouraged. Experiments must be configured as user properties instead of events properties. Please, contact Data team if you have any doubts.',
          'In order to send the experiment, see trackExperiment()'
          );

        return;
      }

      eventProperties["domain"] = eventProperties["domain"] || this.retrieveDomain();
      eventProperties["path"] = eventProperties["path"] || window.location.pathname;

      if (!window.amplitude) {
        this.enqueueEvents(eventName, eventProperties, callback);
        return;
      }

      if (this.eventsQueue.length) {
        this.trackQueueEvents();
      }

      const project = this.getProject();

      if (!this.isActive()) {
        console.warn(
          'Amplitude is currently disabled in this environment.',
          'Please, ask to the team to enable it if you need it.',
          'Meanwhile, here it is the event that was going to be send to Amplitude:',
          {
            project: project,
            eventName: eventName,
            eventProperties: eventProperties,
          }
        );

        return;
      }

      if (this.hasToTrackUserProperties(eventName, callback)) {
        global.habTracker.amplitude.trackUserProperties(eventName, eventProperties, callback);
      } else {
        this.trackOptimizeExperiment(project);
        amplitude.getInstance(project).logEvent(eventName, eventProperties);
      }
    },

    hasToTrackUserProperties: function (eventName, callback) {
      if (
        !global.habTracker.amplitude.trackedUserProperties ||
        typeof callback === 'function'
      ) {
        return true;
      }

      return (global.habTracker.amplitude.userPropertiesEvents.indexOf(eventName) >= 0);
    },

    trackUserProperties: function (eventName, eventProperties, callback) {
      if (!window.amplitude) {
        return;
      }
      global.habTracker.amplitude.trackedUserProperties = true;

      const properties_url = '/p/api/amplitude/user/properties';
      $.ajax({
        url: properties_url,
        method: 'GET',
        type: 'JSON',
        success: function (userProperties) {
          if (userProperties) {
            const project = window.habTrackerOptions.amplitude.project;
            amplitude.getInstance(project).setUserProperties(userProperties);
            amplitude.getInstance(project).logEvent(eventName, eventProperties);

            if (typeof callback === 'function') {
              callback();
            }
          }
        },
      });
    },

    trackOptimizeExperiment: function (project) {
      // Optimize JavaScript API: Debugging resources
      // @see https://support.google.com/optimize/answer/9059383?hl=en

      function gtag() {
        if (window.dataLayer) {
          dataLayer.push(arguments);
        }
      }

      gtag('event', 'optimize.callback', {
        callback: function (experiment_variant, experiment_id) {
          var identify = new amplitude.Identify().append(
            'experiment - optimize - ' + experiment_id,
            experiment_variant
          );
          amplitude.getInstance(project).identify(identify);
        },
      });
    },

    retrieveDomain: function () {
      var slices = window.location.hostname.split('.');

      // Retrieve the main domain and TLD ignoraring subdomains
      return slices.slice(-2).join('.');
    },

    userPropertiesEvents: [
      'business full register success', //Business - Business Register
      'business register success', //Business - Business Register
      'change subscription staff', //Business - Subscription purchase
      'change subscription user', //Business - Subscription purchase
      'service request manual purchased success', //Business - Service request manual purchase
      'view business list', // Business - Business Directory
      'service request success', //Homeowners - Service request
      'view service request verify phone', //Homeowners - verify phone
      'service request phone checking', //Homeowners - phone checking
      'service request phone verified', //Homeowners - phone verified
      'view home', //Logout redirects to homepage
    ],
  };
})(window);
