$(document).ready(function() {
  $('.rotate').click(function() {
    var $icon = $(this).find('.icon-rotate');
    var toOpenIcon = 'icon-plus-circle-o';
    var toCloseIcon = 'icon-minus-circle-o';
    var $collapsable = $(this).find('.collapse');
    $collapsable.collapse('toggle');
    $icon.toggleClass('opened');
    $icon.toggleClass(toCloseIcon);
    $icon.toggleClass(toOpenIcon);
  });

  $('.btn[data-load-on-click]').on('click', function() {
    $(this).addClass('btn-spinner disabled');
  });

  var hasWhitelist =
    $.fn.tooltip &&
    $.fn.tooltip.Constructor &&
    $.fn.tooltip.Constructor.DEFAULTS &&
    $.fn.tooltip.Constructor.DEFAULTS.whiteList['*'];

  if (hasWhitelist) {
    $.fn.tooltip.Constructor.DEFAULTS.whiteList['*'].push(/^data-[\w-]+$/i);
  }
  const notif_url = '/p/api/notification';
  const event_url = '/p/api/amplitude/event';

  $.ajax(notif_url).done(function(data) {
    if (data.status === 'success') {
      data.message.forEach(function(notice) {
        Utilities.showNoticeWithoutDispatcher({
          id: notice.id,
          type: notice.type,
          text: notice.body,
        });
      });
    }
  });

  fetch(event_url)
    .then((response) => response.json())
    .catch((error) => {})
    .then((response) => {
      if (!response) return;
      response.message.forEach(function(event) {
        habTracker.track(event.name, event.properties);
      });
    });
});
