$(function() {
  var eventSent = {};

  $('[data-track-show-event]').each(function() {
    var trackEventData = $(this).data('track-show-event');

    if (trackEventData) {
      var gaData = trackEventData.split('.');

      trackAnalyticsEvent(gaData[0], gaData[1], gaData[2]);
    }
  });

  $('[data-track-focus-out-event]').on('focusout', function(e) {
    var target = $(this);

    if (target.is('input, textarea')) {
      if (target.val()) {
        sendFocusOutEvent(e);
      }
    } else {
      sendFocusOutEvent(e);
    }
  });

  $(document.body).on('click', '[data-amplitude]', function() {
    var amplitudeProperties = this.dataset.amplitudeProperties;
    var event = this.dataset.amplitude;

    //Due to the amount of places where this event can be fired,
    //we insert url property here, this is a refactor.
    if (event === "click register trigger" && amplitudeProperties) {
      amplitudeProperties = addUrlToEvent(amplitudeProperties);
    }

    if (amplitudeProperties) {
      habTracker.track(this.dataset.amplitude, JSON.parse(amplitudeProperties));
    } else {
      habTracker.track(this.dataset.amplitude);
    }
  });

  function addUrlToEvent(amplitudeProperties) {
    var amplitudeProperties = JSON.parse(amplitudeProperties);
    amplitudeProperties['URL'] = location.href;
    return JSON.stringify(amplitudeProperties);
  }

  function eventWasSent(eventName) {
    return eventName in eventSent;
  }

  function sendFocusOutEvent(event) {
    var trackEventData = $(event.target).data('track-focus-out-event');
    var gaData = '';

    if (!eventWasSent(trackEventData) && trackEventData) {
      gaData = trackEventData.split('.');

      trackAnalyticsEvent(gaData[0], gaData[1], gaData[2]);
      eventSent[trackEventData] = true;
    }
  }
});
