jQuery(function($) {
  var MIN_DESKTOP_MENU = 992;
  var menu_selector_classes = [
    '.menu .menu-toggle',
  ];
  var submenu_item_class = '.submenu-link';

  var isDesktop = function() {
    return $(window).width() > MIN_DESKTOP_MENU;
  };

  var sendAmplitudeEvent = function(element) {
    window.habTracker.track(
      $(element).data('amplitude'),
      $(element).data('amplitude-properties')
    );
  }

  menu_selector_classes.forEach(function(menu_selector_class) {
    var $navbar = $('#main-navbar');

    $navbar.on('click',submenu_item_class, function() {
      sendAmplitudeEvent(this);
    })

    $navbar.on('click', menu_selector_class, function(ev) {

      var clickedIem = $(this);
      var href = clickedIem.attr('href');

      if (href) {
        sendAmplitudeEvent(clickedIem);
        window.location.href = href;
        return;
      }

      ev.stopPropagation();
      var $menu = $(this).closest('.menu');
      var openedMenuSelector = '.menu.active.hover';
      var openedClassesIndicator = 'active hover';
      var alreadyOpened = $menu.hasClass('hover') && !$menu.hasClass('active');
      var isCollapsedItem = $(this).parents(openedMenuSelector).length > 0;

      if (!isCollapsedItem) {
        $(openedMenuSelector).removeClass(openedClassesIndicator);
      }

      if (alreadyOpened) {
        $menu.removeClass('hover');
      } else {
        $menu
          .add($menu.parentsUntil($navbar, '.menu'))
          .siblings()
          .removeClass(openedClassesIndicator);
      }

      $menu.toggleClass(openedClassesIndicator);

      if (!$(this).hasClass('noncollapsible')) {
        ev.preventDefault();
      }

      var onBodyClick = function(ev) {
        if (!$(ev.target).closest(openedMenuSelector).length) {
          $navbar.find('.menu').removeClass(openedClassesIndicator);
          $(document.body).off('click', onBodyClick);
        }
      };

      $(document.body).on('click', onBodyClick);
    });

    var addHoverEventsInDesktop = function() {
      $navbar.on(
        'mouseenter touchenter',
        menu_selector_class,
        addHoverIntoSiblings
      );

      $navbar.on('mouseleave', '.menu.hover', function() {
        $(this).removeClass('hover');
      });
    };

    var addHoverIntoSiblings = function() {
      var $menu = $(this).closest('.menu');
      $menu.addClass('hover');
      $menu
        .add($menu.parentsUntil($navbar, '.menu'))
        .siblings('.menu')
        .removeClass('hover');
    };

    var removeHoverEventsInMobile = function() {
      $navbar.off(
        'mouseenter touchenter',
        menu_selector_class,
        addHoverIntoSiblings
      );
      $navbar.off('mouseleave', '.menu.hover');
    };

    if (isDesktop()) {
      addHoverEventsInDesktop();
    }

    $(window).resize(function() {
      if (isDesktop()) {
        addHoverEventsInDesktop();
      } else {
        removeHoverEventsInMobile();
      }
    });
  });
});
