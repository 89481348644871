$(document).ready(function() {

  // Due to the amount of places where this event can be fired,
  // we found more suitable to fire it from here
  $('[data-amplitude="click business register start"]').click(function() {
    habTracker.track('click business register start');
  });
});

// Business Register Amplitude event handlers
