jQuery(function($) {
  var $navbar = $('#main-navbar');

  $navbar.wrap('<div id="main-navbar-wrapper"></div>');

  resizeMainMenu();

  window.onresize = function() {
    resizeMainMenu();
  };

  function resizeMainMenu() {
    var bottomBorderHeight = 1;
    if ($navbar.hasClass('navbar-affix')) {
      $('#main-navbar-wrapper').css(
        'height',
        parseInt($navbar.css('height')) + 'px'
      );
    }
  }
});
