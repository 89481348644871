$(document).ready(function() {
  if ($('[rel="album-add"]').length) {
    import('./utils/album/upload').then(({ AddToAlbum, AlbumController }) => {
      window.AddToAlbum = AddToAlbum;
      window.AlbumController = AlbumController;

      AddToAlbum.init();
    });
  }
});
